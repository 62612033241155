import React  from 'react'
import footerBgImg from "../assets/images/bg/footer-bg.png";
import { useParams } from 'react-router';
import { ConvertToHtml } from '../helpers/ConvertToHtml';

export const ServiceSteps = ({data}) => {
    const params = useParams()
    const { lang } = params


    return (
        <section className="section bg--dgray">
            <img className="section--bg t50 r0" src={footerBgImg} alt="img" />
            <div className="container">
                <div className="row bottom-70">
                    <div className="col-12">
                        <div className="heading heading--white">
                            <span className="heading__pre-title">{data?.preTitle[lang]}</span>
                            <h3 className="heading__title">{data?.title[lang]}</h3>
                        </div>
                    </div>
                </div>
                <div className="row offset-50">
                    <div className="col-md-6 col-xl-3">
                        <div className="icon-item icon-item--white">
                            <div className="icon-item__count ">
                                <span className="box-count--green">01</span>
                            </div>
                            <h6 className="icon-item__title">{data?.steps.one.title[lang]}</h6>
                            <p className="icon-item__text" dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.steps.one.description[lang]) }} />
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="icon-item icon-item--white">
                            <div className="icon-item__count">
                                <span className="box-count--green">02</span>
                            </div>
                            <h6 className="icon-item__title">{data?.steps.two.title[lang]}</h6>
                            <p className="icon-item__text" dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.steps.two.description[lang]) }} />

                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="icon-item icon-item--white">
                            <div className="icon-item__count">
                                <span className="box-count--green">03</span>
                            </div>
                            <h6 className="icon-item__title">{data?.steps.three.title[lang]}</h6>
                            <p className="icon-item__text" dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.steps.three.description[lang]) }} />

                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3">
                        <div className="icon-item icon-item--white">
                            <div className="icon-item__count">
                                <span className="box-count--green">04</span>
                            </div>
                            <h6 className="icon-item__title">{data?.steps.for.title[lang]}</h6>
                            <p className='icon-item__text' dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.steps.for.description[lang]) }} />

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import { IconMail, IconMapPin, IconPhone} from "@tabler/icons-react";

export const OfficeDetails = ({title, phones, email, address, socialsMedia}) => {

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10}}>
                <h5 className="contacts__title">{title}</h5>
                {socialsMedia ?
                    <ul className="socials socials--dark list--reset" style={{marginBottom: 8}}>
                        <li className="socials__item"><a className="socials__link contactHoverSvg" href={socialsMedia.facebook}>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.3828 0.756876C12.2453 0.77813 13.9279 1.23589 15.4306 2.13016C16.9154 3.00656 18.1505 4.24921 19.0178 5.73928C19.9066 7.25101 20.3616 8.94381 20.3828 10.8177C20.33 13.3816 19.5214 15.5714 17.9568 17.3871C16.3922 19.2029 14.3884 20.3262 12.314 20.7568V13.5685H14.2752L14.7187 10.7436H11.7491V8.89344C11.7326 8.50988 11.8539 8.13308 12.091 7.83117C12.3285 7.52843 12.7467 7.36931 13.3457 7.35381H15.1389V4.87931C15.1132 4.87103 14.869 4.83829 14.4065 4.78109C13.8819 4.7197 13.3543 4.68691 12.8262 4.68286C11.6308 4.68838 10.6854 5.02559 9.98996 5.69449C9.2945 6.36317 8.93926 7.33062 8.92422 8.59683V10.7436H6.66433V13.5685H8.92422V20.7568C6.3772 20.3262 4.37337 19.2029 2.8088 17.3872C1.24423 15.5714 0.435597 13.3816 0.382812 10.8177C0.403932 8.94373 0.858922 7.25093 1.74778 5.73928C2.61512 4.24919 3.85022 3.00653 5.33499 2.13012C6.83766 1.23604 8.52025 0.778318 10.3828 0.756876Z" fill="black"/>
                            </svg>
                        </a></li>
                    </ul>
                    : null}
            </div>
            <div className="contacts-item">
                <div className="contacts-item__img">
                    <IconPhone size={30} stroke={1}/>
                </div>
                <div className="contacts-item__details">
                    {
                        phones?.map((el, i) => (
                            <a className="contacts-item__link" href={`tel:${el}`} key={el+i}>{el}</a>
                        ))
                    }
                </div>
            </div>
            <div className="contacts-item">
                <div className="contacts-item__img">
                    <IconMail size={30} stroke={1}/>
                </div>
                <div className="contacts-item__details">
                    <a className="contacts-item__link"
                       href="mailto:transporteriumus@gmail.com"
                    >{email}</a>
                </div>
            </div>
            <div className="contacts-item">
                <div className="contacts-item__img">
                    <IconMapPin size={30} stroke={1}/>
                </div>
                <div className="contacts-item__details"><span>{address}</span>
                </div>
            </div>
        </>
    )
}
import React, { useState } from 'react';
import langsLib from "../assets/libs/LangsLib.json"
import { useParams } from "react-router";
import axios from "axios";
import { useForm } from "react-hook-form";
import { StatusAlert } from './StatusAlert';

export const Quote = (props) => {
    const { lang } = useParams();
    const { handleSubmit, register, reset } = useForm()
    const [alert, setAlert] = useState(null)

    const Submit = (data) => {
        axios.post(process.env.REACT_APP_NODE_API + '/contact/sendReview', {
            "name": data.name,
            "phone": data.phone,
            "email": data.email,
            "subject": data.subject,
            "text": data.message
        }).then(r => {
            console.log(r)
            if (!r.data.error) {
                reset()
                setAlert('1')
            } else {
                setAlert('2')
            }
            setTimeout(() => setAlert(null), 5000)
        })
    }

    return (
        <div className="quote-section quote-bg">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-5 col-lg-7">
                        <div className="quote-form-section">
                            <span className="title">{langsLib.quote.name[lang]}</span>
                            <h2 className="subtitle">{langsLib.quote.title[lang]}</h2>
                            <form onSubmit={handleSubmit(Submit)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-element">
                                            <input
                                                name="name"
                                                type="text"
                                                placeholder={langsLib.quote.inputs.name[lang]}
                                                required
                                                {...register('name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-element">
                                            <input
                                                name="email"
                                                type="email"
                                                placeholder={langsLib.quote.inputs.email[lang]}
                                                required
                                                {...register('email')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-element">
                                            <input
                                                name="phone"
                                                type="tel"
                                                pattern={"^\\+\\d+$"}
                                                placeholder={langsLib.quote.inputs.phone[lang]}
                                                required
                                                {...register('phone')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-element">
                                            <div className="form-element">
                                                <input
                                                    name="subject"
                                                    type="text"
                                                    placeholder={langsLib.quote.inputs.subject[lang]}
                                                    required
                                                    {...register('subject')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6" id={'text'}>
                                        <div className="form-element">
                                            <textarea
                                                name="text"
                                                placeholder={langsLib.quote.inputs.text[lang]}
                                                required
                                                {...register('message')}
                                            />
                                        </div>
                                    </div>

                                    {alert && <div className="col-12">
                                        <StatusAlert show={alert} />
                                    </div>}
                                    <div className="col-lg-12">
                                        <div className="form-element mb-0">
                                            <button type="submit" className="boxed-btn"><span>{langsLib.quote.inputs.button[lang]}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from 'react'
import '../assets/style/services.scss'
import draftToHtml from "draftjs-to-html";
export const Freight = ({ content }) => {

    return (
        <>
            <section className="section service-details">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="singleServiceContent" dangerouslySetInnerHTML={{ __html: draftToHtml({ ...content, "entityMap": content?.entityMap ? content.entityMap : {} }) }}>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import langsLib from "../../assets/libs/LangsLib.json";

export const Achievement = ({data}) => {
    const [isElementInView, setIsElementInView] = useState(false);
    const [numbers, setNumbers] = useState({ num1: 0, num2: 0, num3: 0 });
    const {lang} = useParams();
    const [mapData, setMapData] = useState(null);
    const [mapImg, setMapImg] = useState(null);
    const [mapTitle, setMapTitle] = useState(null);

    useEffect(() => {
        setMapData(data.numbers);
        setMapImg(data.image);
        setMapTitle(data.title);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const targetElement = document.getElementById('elementAbout');

            if (targetElement) {
                const elementTop = targetElement.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementTop < windowHeight - 50) {
                    setIsElementInView(true);
                } else {
                    setIsElementInView(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const timeOut = 60;
        const num1 = mapData ? mapData[0][lang].number : null;
        const num2 = mapData ? mapData[1][lang].number : null;
        const num3 = mapData ? mapData[2][lang].number : null;

        if (isElementInView) {
            const intervalId1 = setInterval(() => {
                setNumbers(prevNumbers => {
                    const updatedNum1 = prevNumbers.num1 < num1 ? prevNumbers.num1 + 1 : prevNumbers.num1;
                    return { ...prevNumbers, num1: updatedNum1 };
                });
            }, timeOut * (num3 / num1));

            const intervalId2 = setInterval(() => {
                setNumbers(prevNumbers => {
                    const updatedNum2 = prevNumbers.num2 < num2 ? prevNumbers.num2 + 1 : prevNumbers.num2;
                    return { ...prevNumbers, num2: updatedNum2 };
                });
            }, timeOut * (num3 / num2));

            const intervalId3 = setInterval(() => {
                setNumbers(prevNumbers => {
                    const updatedNum3 = prevNumbers.num3 < num3 ? prevNumbers.num3 + 1 : prevNumbers.num3;
                    return { ...prevNumbers, num3: updatedNum3 };
                });
            }, timeOut);

            return () => {
                clearInterval(intervalId1);
                clearInterval(intervalId2);
                clearInterval(intervalId3);
            };
        }
    }, [isElementInView, lang, mapData]);

    return (
        <section className="achievement-section" ><img className="achievement-section__bg"
                                                      src={mapImg ? mapImg : null} alt="img"/>
            <div className="container achievement-cont" id={"elementAbout"}>
                <div className="row bottom-50">
                    <div className="col-12">
                        <div className="heading heading--center"><span
                            className="heading__pre-title">{langsLib.achievement[lang]}</span>
                            <h3 className="heading__title">{mapTitle ? mapTitle[lang] : null}</h3>
                        </div>
                    </div>
                </div>
                <div className="row offset-50">
                    <div className="col-md-6 col-lg-4 text-center">
                        <div className="counter counter--blue">
                            <div className="counter__top"><span
                                className="js-counter counter__count">{numbers.num1}</span><span
                                className="counter__subject">{mapData ? mapData[0][lang].type : null}</span></div>
                            <div className="counter__lower"><span className="counter__details">{mapData ? mapData[0][lang].dis : null}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 text-center">
                        <div className="counter counter--blue">
                            <div className="counter__top"><span
                                className="js-counter counter__count">{numbers.num2}</span><span
                                className="counter__subject">{mapData ? mapData[1][lang].type : null}</span></div>
                            <div className="counter__lower"><span className="counter__details">{mapData ? mapData[1][lang].dis : null}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 text-center">
                        <div className="counter counter--blue">
                            <div className="counter__top"><span
                                className="js-counter counter__count">{numbers.num3}</span><span
                                className="counter__subject">{mapData ? mapData[2][lang].type : null}</span></div>
                            <div className="counter__lower"><span className="counter__details">{mapData ? mapData[2][lang].dis : null}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
import { Banner } from "../../components/banner";
import { OfficeDetails } from "./officeDetails";
import { useForm } from "react-hook-form";
import { Footer } from "../../components/footer";
import React, { useEffect, useState } from "react";
import { Header } from "../../components/header";
import axios from "axios";
import { useParams } from "react-router";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { Loader } from "../../components/loader";
import langsLib from "../../assets/libs/LangsLib.json"
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { StatusAlert } from "../../components/StatusAlert";

export const ContactUs = () => {
    const { lang } = useParams();
    const { handleSubmit, register, reset } = useForm();
    const [bannerData, setBannerData] = useState(null);
    const [officeData, setOfficeData] = useState(null);
    const [mapArr, setMapArr] = useState(null);
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        requests();
    }, [])

    const requests = async () => {
        await axios.get(process.env.REACT_APP_NODE_API + '/contact/getBanner').then(res => {
            setBannerData(res.data.content[0]);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/contact/getAllOfficeInfo').then(res => {
            setOfficeData(res.data);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/contact/getMap').then(r => {
            if (!r.data.error) {
                setMapArr(r.data.content)
            }
        })
    }


    const Submit = (data) => {
        axios.post(process.env.REACT_APP_NODE_API + '/contact/sendReview', {
            "name": data.name,
            "phone": data.phone,
            "email": data.email,
            "subject": data.subject,
            "text": data.message
        }).then(r => {
            if (!r.data.error) {
                reset()
                setAlert('1')
            } else {
                setAlert('2')
            }
            setTimeout(() => setAlert(null), 5000)
        })
    }

    return (
        mapArr ?
            <>
                <Header />
                <div className="page-wrapper">
                    <main className="main">
                        <Banner title={langsLib.contactBanner[lang]} img={bannerData ? bannerData : null} />
                        <section className="section contacts pb-0">
                            <img className="contacts__bg" src="https://demo.artureanec.com/html/transx/img/contacts-map.png" alt="map" />
                            <div className="container">
                                <div className="contactus__details">
                                    {officeData?.map((item, i) => (
                                        <div key={i}>
                                            <OfficeDetails
                                                title={item.name[lang]}
                                                phones={item.phones}
                                                email={item.email}
                                                address={item.address[lang]}
                                                socialsMedia={item?.social}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>

                        <section className="section">
                            <div className="container">
                                <div className="row flex-column-reverse flex-lg-row">
                                    <div className="col-lg-6">
                                        <div className="contacts-map" id="map">
                                            {mapArr[0] ? <APIProvider apiKey={process.env.REACT_APP_MAP_API_KEY ? process.env.REACT_APP_MAP_API_KEY : ''}>
                                                <Map defaultCenter={{
                                                    lat: +mapArr[0]?.latitude,
                                                    lng: +mapArr[0]?.longitude
                                                }} defaultZoom={11}>
                                                    {
                                                        mapArr?.map((el, i) => (
                                                            <Marker key={i} position={{
                                                                lat: +el.latitude,
                                                                lng: +el.longitude
                                                            }} title={el.name} label={el.name} />
                                                        ))
                                                    }
                                                </Map>
                                            </APIProvider> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <form onSubmit={handleSubmit(Submit)} className="form contact-form" autoComplete="off">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h5 className="contact-form__subtitle">{langsLib.contactReview.title[lang]}</h5>
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form__field" type="text"
                                                        placeholder={langsLib.quote.inputs.name[lang]}
                                                        {...register('name')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form__field" type="email"
                                                        placeholder={langsLib.quote.inputs.email[lang]}
                                                        {...register('email')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form__field" type="tel"
                                                        placeholder={langsLib.quote.inputs.phone[lang]}
                                                        {...register('phone')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <input className="form__field" type="text"
                                                        placeholder={langsLib.quote.inputs.subject[lang]}
                                                        {...register('subject')}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <textarea className="form__field form__message message--large"
                                                        placeholder={langsLib.quote.inputs.text[lang]}
                                                        {...register('message')}
                                                    />
                                                </div>
                                                {alert && <div className="col-12">
                                                    <StatusAlert show={alert} />
                                                </div>}
                                                <div className="col-12">
                                                    <button className="button button--green" type="submit">
                                                        <span>{langsLib.contactReview.button[lang]}</span>
                                                        <IconArrowNarrowRight stroke={1.3} size={30} />
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </main>
                    <Footer />
                </div>
            </>
            :
            <Loader />
    )
}
import React from 'react';
import {useParams} from "react-router";
import {ConvertToHtml} from "../../helpers/ConvertToHtml";
import badgeImg from '../../assets/images/icons/badge_img.png'

export const About = ({info1Data, info2Data}) => {
    const {lang} = useParams();

    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5"><img className="w-100 bottom-50" src={info1Data?.image}
                                                                alt="img"/>
                            <div className="img-badge"><img className="img-badge__img" src={badgeImg}
                                                            alt="img"/>
                                <h3 className="img-badge__title bottom-0">{info1Data?.slogan[lang]}</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 offset-xl-1">
                            <div className="heading bottom-20"><span className="heading__pre-title">About us</span>
                                <h3 className="heading__title" dangerouslySetInnerHTML={{ __html: ConvertToHtml(info1Data?.title[lang]) }}></h3>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(info1Data?.dis[lang]) }}></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pt-0">
                <div className="container">
                    <div className="row flex-column-reverse flex-lg-row">
                        <div className="col-lg-6 top-50 top-lg-0">
                            <div className="heading bottom-20">
                                <h3 className="heading__title" dangerouslySetInnerHTML={{ __html: ConvertToHtml(info2Data?.title[lang]) }}></h3>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(info2Data?.description[lang]) }}></p>
                        </div>
                        <div className="col-lg-6 col-xl-5 offset-xl-1"><img className="w-100"
                                                                            src={info2Data?.images} alt="img"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
import React from 'react';
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {ConvertToHtml} from "../../helpers/ConvertToHtml";
import langsLib from "../../assets/libs/LangsLib.json";
import { ServiceLeave } from '../../components/ServiceLeave';

export const TransportServices = ({serviceData}) => {
    const {lang} = useParams();

    return (
        <section className="section">
            <div className="container">
                <div className="row bottom-70">
                    <div className="col-lg-4">
                        <div className="heading bottom-40"><span className="heading__pre-title">{langsLib.homepageServices.title[lang]}</span>
                            <h3 className="heading__title">{serviceData ? serviceData.title[lang] : null}</h3><span
                                className="heading__layout">{langsLib.homepageServices.title[lang]}</span>
                        </div>
                        <Link className="button button--green d-none d-lg-inline-block"
                              to={'/service/'+ lang}><span>{langsLib.homepageServices.btn[lang]}</span>
                            <svg className="icon">
                                <use xlinkHref="#arrow"></use>
                            </svg>
                        </Link>
                    </div>
                    <div className="col-lg-8">
                        <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(serviceData?.text1[lang]) }}></p>
                        <p className="bottom-0" dangerouslySetInnerHTML={{ __html: ConvertToHtml(serviceData?.text2[lang]) }}></p>
                    </div>
                </div>
                <div className="row top-70 d-flex d-lg-none mb-3">
                    <div className="col-12 text-center"><Link className="button button--green" to="#"><span>{langsLib.homepageServices.btn[lang]}</span>
                        <svg className="icon">
                            <use xlinkHref="#arrow"></use>
                        </svg>
                    </Link></div>
                </div>
            </div>
            <ServiceLeave/>
        </section>
    );
}
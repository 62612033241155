import { Alert } from '@mui/material'
import React from 'react'
import langsLib from '../assets/libs/LangsLib.json'
import { useParams } from 'react-router';

export const StatusAlert = ({ show }) => {

    const { lang } = useParams();

    return (
        <>

            {
                show === '1' &&
                <Alert severity="success" className="mb-4">
                    <strong> {langsLib.status.successful[lang].split("!")[0]}!</strong>
                    {langsLib.status.successful[lang].split("!")[1]}
                </Alert>

            }

            {
                show === '2' &&
                <Alert severity="error" className="mb-4">
                    {langsLib.status.error[lang]}
                </Alert>

            }
        </>
    )
}

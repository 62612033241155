import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../../assets/style/calculator.scss";
import langsLib from "../../assets/libs/LangsLib.json";
import { useParams } from "react-router";
import axios from "axios";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { StatusAlert } from '../../components/StatusAlert';
import { CalculatorCustomsSelect } from '../../components/CalculatorCustomsSelect';


export const QuoteTrack = ({ selectData }) => {
    const { lang } = useParams();
    const [loading, setLoading]=useState(false)
    const [variant, setVariant] = useState()
    const [form, setForm] = useState({
        origin: "",
        delivery: "",
        service: "",
        customs: [],
        variant: "",
        weight: "",
        height: "",
        width: "",
        length: "",
        name: "",
        tel: "",
        email: "",
        originCity: "",
        deliveryCity: ""
    });

    useEffect(() => {
        setVariant('')
        setForm({
            origin: "",
            delivery: "",
            service: "",
            customs: [],
            variant: "",
            weight: "",
            height: "",
            width: "",
            length: "",
            name: "",
            tel: "",
            email: "",
            originCity: "",
            deliveryCity: ""
        })
    }, [lang])


    const [alert, setAlert] = useState(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'service') {
            if (form.variant) {
                setForm(prevState => ({
                    ...prevState,
                    variant: ''
                }));
            }
            if (variant) {
                setVariant('')
            }
            langsLib.calculator.inputs.typeServices.value.forEach(i => {
                if (i.variant.length > 0 && i.name[lang] === value) {
                    setVariant({
                        name: langsLib.calculator.inputs.typeServices.variantName[lang],
                        value: i.variant.map((e) => { return e[lang] })
                    })
                }

            })
        }
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        axios.post(process.env.REACT_APP_NODE_API + '/pages/calculator/sendDeliveryRequest', {
            "name": form.name,
            "phone": form.tel,
            "email": form.email,
            "requests": form
        }).then(r => {
            if (!r.data.error) {
                setLoading(false)
                setAlert('1')
                setForm({
                    origin: "",
                    delivery: "",
                    service: "",
                    customs: [],
                    weight: "",
                    height: "",
                    width: "",
                    length: "",
                    name: "",
                    tel: "",
                    email: "",
                    variant: "",
                    originCity: "",
                    deliveryCity: ""
                });
                setVariant('')
            } else {
                setLoading(false)
                setAlert('2')
            }
            setTimeout(() => setAlert(null), 5000)
        })
    };
    const customsSelect = {
        name: langsLib.calculator.inputs.customsProcedures[lang],
        value: selectData.customs[lang],
        text:langsLib.calculator.inputs.multiple[lang]
    }
    return (
        <section className="section qoute-section bg--lgray pb-0">
            <div className="quote-section__bg"><img className="img--bg" src="img/quote-bg.jpg" alt="bg" /></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 d-flex flex-column justify-content-between">
                        <div className="heading heading--white"><span className="heading__pre-title">{langsLib.calculator.request[lang]}</span>
                            <h3 className="heading__title">{langsLib.calculator.backgroundContent.title[lang]}</h3><span
                                className="heading__layout layout--white">{langsLib.calculator.request[lang]}</span>
                            <p className="heading__text color--white">{langsLib.calculator.backgroundContent.text[lang]}</p>
                        </div>
                        <div className="contact-trigger top-50 top-lg-0"><img className="contact-trigger__img"
                            src="img/contact_background.png"
                            alt="img" />
                            <h4 className="contact-trigger__title">{langsLib.calculator.backgroundContent.slogan.text[lang]}</h4><Link
                                className="button button--white" to={'/contact/' + lang}><span>{langsLib.calculator.backgroundContent.slogan.btn[lang]}</span>
                                <IconArrowNarrowRight stroke={1.3} size={30} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-7 top-50 top-lg-0">
                        <div className="form-wrapper">
                            <form className="form calculator-form" action="#" onSubmit={handleSubmit}>
                                <h5 className="calculator-form__title">{langsLib.calculator.titleForHome[lang]}</h5>
                                <div className="row bottom-5 pl-3 pr-3">
                                    <div>
                                        <div className="row bottom-5">
                                            <div className="col-sm-6">
                                                <select className="form__select nice-select " name="origin" value={form.origin} onChange={handleChange} required>
                                                    <option value="">{langsLib.calculator.inputs.CountryOfOrigin[lang]} </option>
                                                    {selectData.origin[lang]?.map((elem, i) => (
                                                        <option key={i} value={elem}>{elem}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <input
                                                    className="form__field"
                                                    type="text"
                                                    value={form.originCity}
                                                    name="originCity"
                                                    onChange={handleChange}
                                                    placeholder={langsLib.calculator.inputs.city[lang]}
                                                    required
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <select className="form__select nice-select" name="delivery" value={form.delivery} onChange={handleChange} required>
                                                    <option value="">{langsLib.calculator.inputs.TypeOfDelivery[lang]} </option>
                                                    {selectData.delivery[lang]?.map((elem, i) => (
                                                        <option key={i} value={elem}>{elem}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <input
                                                    className="form__field"
                                                    type="text"
                                                    value={form.deliveryCity}
                                                    name="deliveryCity"
                                                    onChange={handleChange}
                                                    placeholder={langsLib.calculator.inputs.city[lang]}
                                                    required
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <select className="form__select nice-select" name="service" value={form.service} onChange={handleChange} required>
                                                    <option value="">{langsLib.calculator.inputs.typeServices.name[lang]} </option>
                                                    {langsLib.calculator.inputs.typeServices.value?.map((elem, i) => (
                                                        <option key={i} value={elem.name[lang]}>{elem.name[lang]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {variant && <div className="col-sm-12">
                                                <select className="form__select nice-select" name="variant" value={form.variant} onChange={handleChange} required>
                                                    <option value="">{langsLib.calculator.inputs.typeServices.variantName[lang]} </option>
                                                    {variant.value?.map((elem, i) => (
                                                        <option key={i} value={elem}>{elem}</option>
                                                    ))}
                                                </select>
                                            </div>}
                                            <div className="col-sm-12 col-md-12">
                                                <CalculatorCustomsSelect
                                                    data={customsSelect}
                                                    name={"customs"}
                                                    selectValue={form.customs}
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <input className="form__field" type="number" placeholder={langsLib.calculator.inputs.weight[lang]} name={"weight"} value={form.weight} onChange={handleChange} required />
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <input className="form__field" type="number" name="height" placeholder={lang === 'hy' ? 'Բարձր․(սմ)' : langsLib.calculator.inputs.height[lang]} value={form.height} onChange={handleChange} required />
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <input className="form__field" type="number" name="width" placeholder={lang === 'hy' ? 'Լայն․(սմ)' : langsLib.calculator.inputs.width[lang]} value={form.width} onChange={handleChange} required />
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <input className="form__field" type="number" name="length" placeholder={lang === 'hy' ? 'Երկար․(սմ)' : langsLib.calculator.inputs.length[lang]} value={form.length} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <div className='row '>
                                            <div className="col-sm-6 col-md-4">
                                                <input className="form__field" type="text" name="name" placeholder={langsLib.calculator.inputs.name[lang]} value={form.name} onChange={handleChange} required />
                                            </div>
                                            <div className="col-sm-6 col-md-4">
                                                <input className="form__field" type="tel" pattern="^\+\d+$" name="tel" placeholder={lang === 'hy' ? 'Հեռախոսահամար' : langsLib.calculator.inputs.tel[lang]} value={form.tel} onChange={handleChange} required />
                                            </div>

                                            <div className="col-sm-6 col-md-4">
                                                <input className="form__field" type="email" name="email" placeholder={langsLib.calculator.inputs.email[lang]} value={form.email} onChange={handleChange} required />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {alert && <div className="row col-12" >
                                    <StatusAlert show={alert} />
                                </div>}
                                <div className="row mb-5">
                                    <div className="col-12 top-20">
                                        <button disabled={loading} className="button button--green form__button" type="submit"><span>{langsLib.calculator.inputs.button[lang]}</span>
                                            <IconArrowNarrowRight stroke={1.3} size={30} />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
import React  from 'react'
import { useParams } from 'react-router';
import { ConvertToHtml } from '../helpers/ConvertToHtml';

export const ServiceInfo = ({data}) => {
    const params = useParams()
    const { lang } = params
    return (
        <section className="section">
            <div className="container">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-lg-6 top-50 top-lg-0">
                        <div className="heading bottom-20">
                            <h3 className="heading__title" dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.title[lang]) }}>

                            </h3>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.description[lang]) }}>

                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5 offset-xl-1">
                        <img className="w-100" src={data?.images} alt="img" />
                    </div>
                </div>
            </div>
        </section >
    )
}

// CalculatorInput.js
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { MultipleSelectPlaceholder } from "./select";
import { removeForm, updateForm } from "../store/Calculatorstore";
import langsLib from "../assets/libs/LangsLib.json"
import { useParams } from "react-router";
import { CalculatorCustomsSelect } from "./CalculatorCustomsSelect";

export const CalculatorInput = memo(({ element, setClearVariant, clearVariant, index, length, selectData }) => {
    const dispatch = useDispatch();
    const { lang } = useParams();
    const [variant, setVariant] = useState()

    useEffect(() => {
        if (clearVariant) {
            setVariant('')
            setClearVariant(false)
        }
        
    }, [clearVariant])

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'service') {
            if (element.variant) {
                dispatch(updateForm({ id: element.id, name: 'variant', value: '' }))
            }
            if (variant) {
                setVariant('')
            }
            langsLib.calculator.inputs.typeServices.value.forEach(i => {
                if (i.variant.length > 0 && i.name[lang] === value) {
                    setVariant({
                        name: langsLib.calculator.inputs.typeServices.variantName[lang],
                        value: i.variant.map((e) => { return e[lang] })
                    })
                }

            })
        }
        dispatch(updateForm({ id: element.id, name, value }));
    };

    const handleClose = (id) => {
        dispatch(removeForm(id));
    };

    const select = [
        {
            name: langsLib.calculator.inputs.CountryOfOrigin[lang],
            value: selectData.origin[lang]
        },
        {
            name: langsLib.calculator.inputs.TypeOfDelivery[lang],
            value: selectData.delivery[lang],
        },
        {
            name: langsLib.calculator.inputs.customsProcedures[lang],
            value: selectData.customs[lang],
            text:langsLib.calculator.inputs.multiple[lang]
        },
        {
            name: langsLib.calculator.inputs.typeServices.name[lang],
            value: langsLib.calculator.inputs.typeServices.value.map(i => {
                return i.name[lang]
            })
        }
    ];

    return (
        <div>
            <div className=" d-flex flex-wrap justify-content-between padding mb-1">
                <h6 className="calculator-form__title">{langsLib.calculator.request[lang]} {index + 1}</h6>
                {length > 1 && <CloseIcon className="pointer" onClick={() => handleClose(element.id)} />}
            </div>
            <div className="row bottom-20" >
                <div className="col-sm-6 col-md-6">
                    <MultipleSelectPlaceholder
                        data={select[0]}
                        name={"origin"}
                        selectValue={element.origin}
                        handleChange={handleChange}
                    />
                </div>
                <div className="col-sm-6 col-md-6">
                    <input
                        className="form__field"
                        type="text"
                        value={element.originCity}
                        name="originCity"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.city[lang]}
                        required
                    />
                </div>
                <div className="col-sm-6 col-md-6">
                    <MultipleSelectPlaceholder
                        data={select[1]}
                        name={"delivery"}
                        selectValue={element.delivery}
                        handleChange={handleChange}
                    />
                </div>
                <div className="col-sm-6 col-md-6">
                    <input
                        className="form__field"
                        type="text"
                        value={element.deliveryCity}
                        name="deliveryCity"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.city[lang]}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-12">
                    <MultipleSelectPlaceholder
                        data={select[3]}
                        name={"service"}
                        selectValue={element.service}
                        handleChange={handleChange}
                    />
                </div>
                {variant && <div className="col-sm-12 col-md-12">
                    <MultipleSelectPlaceholder
                        data={variant}
                        name={"variant"}
                        selectValue={element.variant}
                        handleChange={handleChange}
                    />
                </div>
                }
                <div className="col-sm-12 col-md-12">
                    <CalculatorCustomsSelect
                        data={select[2]}
                        name={"customs"}
                        selectValue={element.customs}
                        handleChange={handleChange}
                    />
                </div>
                <div className="col-sm-6 col-md-3">
                    <input
                        pattern="^[\d.]+$"
                        className="form__field"
                        type="text"
                        value={element.weight}
                        name="weight"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.weight[lang]}
                        required
                    />
                </div>
                <div className="col-sm-6 col-md-3">
                    <input
                        pattern="^[\d.]+$"
                        className="form__field"
                        type="text"
                        value={element.height}
                        name="height"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.height[lang]}
                        required
                    />
                </div>
                <div className="col-sm-6 col-md-3">
                    <input
                        pattern="^[\d.]+$"
                        className="form__field"
                        type="text"
                        value={element.width}
                        name="width"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.width[lang]}
                        required
                    />
                </div>
                <div className="col-sm-6 col-md-3">
                    <input
                        pattern="^[\d.]+$"
                        className="form__field"
                        type="text"
                        value={element.length}
                        name="length"
                        onChange={(e) => handleChange(e)}
                        placeholder={langsLib.calculator.inputs.length[lang]}
                        required
                    />
                </div>

            </div>
        </div>
    );
});


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CalculatorInput } from "./Calculatorinput";
import AddIcon from "@mui/icons-material/Add";
import { addForm, clearForm } from '../store/Calculatorstore';
import { useDispatch } from 'react-redux';
import axios from "axios";
import { useParams } from "react-router";
import langsLib from "../assets/libs/LangsLib.json"
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { StatusAlert } from "./StatusAlert";

export const CalculatorForm = ({ selectData }) => {
    const [loading, setLoading]=useState(false)
    const { lang } = useParams();
    const dispatch = useDispatch()
    const forms = useSelector((state) => state.calculator);
    const [clearVariant, setClearVariant] = useState(false)
    const [userData, setUserData] = useState({
        name: "",
        tel: "",
        email: "",
    }); 
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        setClearVariant(true)
        dispatch(clearForm())
        setUserData({
            name: "",
            tel: "",
            email: "",
        })
    }, [lang])

    const Submit = (e) => {
        e.preventDefault()
        setLoading(true)
        axios.post(process.env.REACT_APP_NODE_API + '/pages/calculator/sendDeliveryRequest', {
            "name": userData.name,
            "phone": userData.tel,
            "email": userData.email,
            "requests": forms
        }).then(r => {
            if (!r.data.error) {
                setLoading(false)
                setAlert('1')
                dispatch(clearForm())
                setClearVariant(true)
                setUserData({
                    name: "",
                    tel: "",
                    email: "",
                })
            } else {
                setLoading(false)
                setAlert('2')
            }
            setTimeout(() => setAlert(null), 5000)
        })
    }

    return (
        <form className="form calculator-form" onSubmit={Submit}>
            <h5 className="calculator-form__title">{langsLib.calculator.title[lang]}</h5>
            <div className="row bottom-30 pl-3 pr-3">
                {forms.map((element, index) => (
                    <CalculatorInput key={element.id} clearVariant={clearVariant} setClearVariant={setClearVariant} index={index} length={forms.length} element={element} selectData={selectData} />
                ))}
                <div className="w-100">
                    <div className="row ">
                        {["name", "tel", "email"].map((name) => (
                            <div key={name} className="col-sm-6 col-md-3">
                                <input
                                    className="form__field"
                                    type={name === "tel" ? "tel" : name === "email" ? "email" : "text"}
                                    pattern={name === "tel" ? "^\\+\\d+$" : null}
                                    name={name}
                                    placeholder={langsLib.calculator.inputs[name][lang]}
                                    onChange={(e) => setUserData(prevUserData => ({ ...prevUserData, [name]: e.target.value }))}
                                    value={userData[name]}
                                    required
                                />
                            </div>
                        ))}
                        <div className={`col-sm-6 col-md-3 `} onClick={() => { dispatch(addForm()) }}>
                            <span className={`form__field form__button d-flex justify-content-center align-items-center ${forms.length === 5 ? "not-allowed" : ""}`}>
                                <AddIcon />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                {alert && <div className="col-12">
                    <StatusAlert show={alert} />
                </div>}
                <div className="col-12 top-20">
                  <button disabled={loading} className="button button--green form__button">
                        <span>{langsLib.calculator.inputs.button[lang]}</span>
                        <IconArrowNarrowRight stroke={1.3} size={30} />
                    </button>
                </div>
            </div>
        </form>
    );
};

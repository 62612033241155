
import carImg from '../../assets/images/bg/car_1.png'
import '../../assets/style/calculator.scss'
import { Banner } from "../../components/banner";
import { Footer } from "../../components/footer";
import { CalculatorForm } from '../../components/Calculatorform';
import { Header } from "../../components/header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import langsLib from "../../assets/libs/LangsLib.json"
import { Loader } from "../../components/loader";
import { useParams } from "react-router";

export const Calculator = () => {
    const { lang } = useParams();
    const [selectData, setSelectData] = useState({
        delivery: {
            en: [],
            ru: [],
            hy: [],
        },
        origin: {
            en: [],
            ru: [],
            hy: [],
        },
        service: {
            en: [],
            ru: [],
            hy: [],
        }
    });

    const [bannerImg, setBannerImg] = useState(null);

    useEffect(() => {
        requests();
    }, [])

    const requests = async () => {
        await axios.get(process.env.REACT_APP_NODE_API + '/pages/calculator/selection').then(res => {
            const { delivery, origin, customs } = res.data.data[0].select;
            setSelectData({
                delivery: {
                    en: delivery.map(elem => elem.value.en),
                    ru: delivery.map(elem => elem.value.ru),
                    hy: delivery.map(elem => elem.value.hy),
                },
                origin: {
                    en: origin.map(elem => elem.value.en),
                    ru: origin.map(elem => elem.value.ru),
                    hy: origin.map(elem => elem.value.hy),
                },
                customs: {
                    en: customs.map(elem => elem.value.en),
                    ru: customs.map(elem => elem.value.ru),
                    hy: customs.map(elem => elem.value.hy),
                }
            });
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/pages/calculator/images').then(res => {
            setBannerImg(res.data.data[0]);
        })
    }

    return (
        bannerImg ? <>
            <Header />
            <div className="page-wrapper">
                <main className="main">
                    <Banner title={langsLib.calculatorBanner[lang]} img={bannerImg?.images} />
                    <section className="section calculator">
                        <div className="container ">
                            <div className="row  gap">
                                <div className="col-lg-8">
                                    <CalculatorForm selectData={selectData} />
                                </div>
                                <div className="col-lg-4 nice-select.wid flex-grow-3 row align-items-center">
                                    <img src={carImg} alt='' />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </> : <Loader />
    )
}


import React from "react";
import langsLib from "../assets/libs/LangsLib.json"
import {useParams} from "react-router";
import svg1 from '../assets/images/icons/1.svg'
import svg2 from '../assets/images/icons/2.svg'
import svg3 from '../assets/images/icons/3.svg'
import svg4 from '../assets/images/icons/4.svg'
import svg5 from '../assets/images/icons/5.svg'
import svg6 from '../assets/images/icons/6.svg'

export const Services = (props) => {
    const {lang} = useParams();

    return (
        <section className="section">
            <div className="container">
                <div className="row bottom-70">
                    <div className="col-12">
                        <div className="heading heading--center">
                            <span className="heading__pre-title">{langsLib.leaveServices.secondTitle[lang]}</span>
                            <h3 className="heading__title">{langsLib.leaveServices.mainTitle[lang]}</h3>
                        </div>
                    </div>
                </div>
                <div className="row offset-50" id={"rows"}>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg1} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.warehouse[lang]}</h6>
                        </div>
                    </div>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg2} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.support[lang]}</h6>
                        </div>
                    </div>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg3} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.cargoInsurance[lang]}</h6>
                        </div>
                    </div>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg4} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.worldwide[lang]}</h6>
                        </div>
                    </div>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg5} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.boxChecking[lang]}</h6>
                        </div>
                    </div>
                    <div
                        className="col-6 col-sm-4 col-lg-3 text-center"
                        id="service-icon"
                    >
                        <div className="icon-item">
                            <div className="icon-item__img">
                                <img src={svg6} alt=""/>
                            </div>
                            <h6 className="icon-item__title bottom-0">{langsLib.leaveServices.alwaysOnTime[lang]}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Carousel from "nuka-carousel"
import {useParams} from "react-router";
import langsLib from "../../assets/libs/LangsLib.json"
import {IconArrowNarrowRight} from "@tabler/icons-react";

const Slide = ({item}) => {
    const videoRef = useRef(null);
    const {lang} = useParams();

    useEffect(() => videoPlayer())

    const videoPlayer = () => {
        if (item.medias.duration && videoRef){
            videoRef.current.play()
            const videoUrl = item.medias.src;
            const videoElement = document.createElement('video');

            videoElement.src = videoUrl;
        }
    };

    return(
        <div key={item._id} className="promo-slider__item">
            <div className="promo-slider__layout"/>
            <picture>
                <source srcSet={item.medias.src} media="(min-width: 992px)"/>
                {(item.medias.src.startsWith('data:video')) ? (
                    <video className="img--bg"
                           src={item.medias.src}
                           ref={videoRef}
                           loop
                           muted
                           playsInline
                    />
                ) : (
                    <img className="img--bg" src={item.medias.src} alt={'img'}/>
                )}
            </picture>
            <div className="align-container">
                <div className="align-container__item">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 col-xl-7">
                                <div className="promo-slider__wrapper-1"><span
                                    className="promo-slider__overlay" style={{whiteSpace: "nowrap"}}>Vila Trans</span>
                                    <h2 className="promo-slider__title">{item.title[lang]}</h2>
                                </div>
                                <div className="promo-slider__wrapper-2">
                                    <p className="promo-slider__subtitle">{item.description[lang]}</p>
                                </div>
                                <div className="promo-slider__wrapper-3"><Link className="button button--promo"
                                                                               to={item.link[lang]}><span>{langsLib.homePageBtn[lang]}</span>
                                    <IconArrowNarrowRight stroke={1.3} size={30}/>
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Promo = ({promoData}) => {
    const [slideData, setSlideData] = useState(null);
    const [caruselInterval, setCaruselInterval] = useState(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        setSlideData(promoData);

        const intervals = Array(promoData?.length).fill(3000);

        setCaruselInterval(intervals);
    }, [promoData]);

    useEffect(() => {
        if (slideData ? slideData[currentSlide]?.medias?.duration : null) {
            setCaruselInterval(prevState => {
                const updatingState = [...prevState];
                updatingState[currentSlide] = slideData[currentSlide]?.medias?.duration * 1000;
                return updatingState
            });
        }
    }, [currentSlide, slideData]);


    const renderBottomCenterControls = ({ currentSlide, goToSlide }) => {
        const totalSlides = slideData?.length; // Total number of slides
        const lines = [];

        const handleClick = (index) => {
            goToSlide(index);
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            setCurrentSlide(currentSlide);
        }, [currentSlide])

        for (let i = 0; i < totalSlides; i++) {

            lines.push(
                <span
                    className={'slide-control'}
                    key={i}
                    style={{
                        display: 'inline-block',
                        width: '60px',
                        height: currentSlide === i ? '5px' : '2px',
                        backgroundColor: currentSlide === i ? '#28a745' : 'white',
                        margin: '0 2px',
                        cursor: 'pointer',
                        transition: '100ms height, background-color'
                    }}
                    onClick={() => {handleClick(i)}}
                    onMouseEnter={(e) => {
                        e.target.style.height = '5px';
                        e.target.style.backgroundColor = '#28a745';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.height = currentSlide === i ? '5px' : '2px'
                        e.target.style.backgroundColor = currentSlide === i ? '#28a745' : 'white'
                    }}
                />
            );
        }
        return <div style={{ textAlign: 'center', marginTop: '10px', position: 'absolute', left: "11%", bottom: '100px', zIndex: "10" }}>{lines}</div>;
    };

    return (
        <div className="promo">
            <div className="promo-slider">
                <Carousel autoplay={true}
                          autoplayInterval={caruselInterval ? caruselInterval[currentSlide] : 3000}
                          wrapAround={true}
                          renderCenterLeftControls={() => null}
                          renderCenterRightControls={() => null}
                          draggable={true}
                          renderBottomCenterControls={renderBottomCenterControls}>

                    {slideData?.map((item) => (
                        <Slide item={item}/>
                    ))}
                </Carousel>
            </div>

        </div>
    );
}

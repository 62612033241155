import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Chip } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};


export const CalculatorCustomsSelect = ({ data, name, handleChange, selectValue }) => {

    return (
        <div >
            <FormControl sx={{ width: '100%', borderColor: "#e2e2e2", mb: "20px" }}>
                <Select
                    sx={{
                        "&:focus": {
                            "&& .MuiOutlinedInput-notchedOutline": {
                                borderColor: '#e2e2e2',
                            }
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#e2e2e2',
                        },
                        borderRadius: 0, "&:hover": {
                            "&& fieldset": {
                                border: "1px solid #e2e2e2 !important"
                            }
                        },

                    }}
                    className="form__select calculator "
                    displayEmpty
                    value={selectValue.length ? selectValue.every(value => data.value.includes(value)) ? selectValue : [] : []}
                    name={name}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <span className='placeholder'>{data?.name}</span>;
                        }
                        return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    }
                    }
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    multiple
                >
                    <MenuItem disabled value="">
                        <span>{data?.text}</span>
                    </MenuItem>
                    {data.value.map((value, i) => (
                        <MenuItem
                            key={i}
                            value={value}
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
import React from 'react';
import {useParams} from "react-router";
import {ConvertToHtml} from "../helpers/ConvertToHtml";
import langsLib from "../assets/libs/LangsLib.json"

export const Features = ({featuresData}) => {
    const {lang} = useParams();

    return (
        <div className="features-section home-2 border-top">
            <div className="container">
                <div className="row feature-content">
                    <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 pr-0">
                        <div className="features">
                            <span className="title">{langsLib.features[lang]}</span>
                            <h2 className="subtitle">{featuresData?.title[lang]}</h2>
                            <div className="feature-lists">
                                <div className="single-feature wow fadeInUp" data-wow-duration="1s">
                                    <div className="icon-wrapper"><img src={featuresData ? featuresData.section1.icon : null} alt="img"/></div>
                                    <div className="feature-details">
                                        <h4>{featuresData?.section1.title[lang]}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(featuresData?.section1.description[lang]) }}></p>
                                    </div>
                                </div>
                                <div className="single-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                                    <div className="icon-wrapper"><img src={featuresData?.section2.icon} alt="img"/></div>
                                    <div className="feature-details">
                                        <h4>{featuresData?.section2.title[lang]}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(featuresData?.section2.description[lang]) }}></p>
                                    </div>
                                </div>
                                <div className="single-feature wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                                    <div className="icon-wrapper"><img src={featuresData?.section3.icon} alt="img"/></div>
                                    <div className="feature-details">
                                        <h4>{featuresData?.section3.title[lang]}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: ConvertToHtml(featuresData?.section3.description[lang]) }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className={'features-img'} src={featuresData?.image} alt="img"/>
                </div>
            </div>
        </div>
    );
}

export default Features;
import React  from "react";
import { useParams } from "react-router";
import { ConvertToHtml } from "../helpers/ConvertToHtml";
import langsLib from "../assets/libs/LangsLib.json"

export const Transportservice = ({data}) => {
    const params = useParams()
    const { lang } = params

    return (
        <>
            <section className="section">
                <div className="container">
                    <div style={{height: "min-content"}} className="row align-items-end bottom-70">
                        <div className="services__title__box">
                            <div className="heading">
                                <span className="heading__pre-title">{langsLib.services.title[lang]}</span>
                                <h3 className="heading__title">{data?.title[lang]}</h3>
                            </div>
                        </div>
                        <div className="services__text__box top-20 top-xl-0 pMarginDel" dangerouslySetInnerHTML={{ __html: ConvertToHtml(data?.description[lang]) }}>

                        </div>
                    </div>
                    <div className="row offset-50">
                        <div className="col-md-6 col-xl-3">
                            <div className="icon-item">
                                <div className="icon-item__img icon-item__img--small">
                                    <img src={data?.transport.car.images} alt="road freight" />
                                </div>
                                <h6 className="icon-item__title">{langsLib.services.truck.title[lang]}</h6>
                                <p className="icon-item__text">
                                    {langsLib.services.truck.text[lang]}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="icon-item">
                                <div className="icon-item__img icon-item__img--small">
                                    <img src={data?.transport.shipping.images} alt="shipping" />
                                </div>
                                <h6 className="icon-item__title">{langsLib.services.ship.title[lang]}</h6>
                                <p className="icon-item__text">
                                    {langsLib.services.ship.text[lang]}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="icon-item">
                                <div className="icon-item__img icon-item__img--small">
                                    <img src={data?.transport.air.images} alt="plane" />
                                </div>
                                <h6 className="icon-item__title">{langsLib.services.plane.title[lang]}</h6>
                                <p className="icon-item__text">
                                    {langsLib.services.plane.text[lang]}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="icon-item">
                                <div className="icon-item__img icon-item__img--small">
                                    <img src={data?.transport.train.images} alt="train" />
                                </div>
                                <h6 className="icon-item__title">{langsLib.services.train.title[lang]}</h6>
                                <p className="icon-item__text">
                                    {langsLib.services.train.text[lang]}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

import React, { useEffect, useState } from 'react'
import { Banner } from '../../components/banner'
import { Transportservice } from '../../components/Transportservice'
import '../../assets/style/services.scss'
import { Footer } from '../../components/footer'
import { Header } from "../../components/header";
import { ServiceInfo } from '../../components/ServiceInfo'
import { ServiceSteps } from '../../components/ServiceSteps.js'
import { ServiceLeave } from '../../components/ServiceLeave'
import axios from 'axios'
import { useParams } from 'react-router'
import {Loader} from "../../components/loader";

export const ServicePage = () => {
    const params = useParams()
    const { lang } = params
    const [servicesData, setServicesData] = useState(null);
    const [transportData, setTransportData] = useState(null);
    const [leaveData, setLeaveData] = useState(null);
    const [stepsData, setStepsData] = useState(null);
    const [infoData, setInfoData] = useState(null);

    useEffect(() => {
        requests();
    }, [])

    const requests = async () => {
        await axios.get(process.env.REACT_APP_NODE_API + "/service/images")
                .then(response => {
                    setServicesData(response.data[0])
                    document.title = 'Vila Trans / ' + response.data[0]?.title[lang]
                })
                .catch((err) => console.log(err));

        await axios.get(process.env.REACT_APP_NODE_API + "/service/transport")
                .then(response => setTransportData(response.data))
                .catch((err) => console.log(err));

        await axios.get(process.env.REACT_APP_NODE_API + "/service/leave")
                .then(response => setLeaveData(response.data[0]))
                .catch((err) => console.log(err));

        await axios.get(process.env.REACT_APP_NODE_API + "/service/steps")
                .then(response => setStepsData(response.data))
                .catch((err) => console.log(err));

        await axios.get(process.env.REACT_APP_NODE_API + "/service/info")
                .then(response => setInfoData(response.data))
                .catch((err) => console.log(err))
    }

    return (
        infoData ?
            <>
                <Header />
                <div className="page-wrapper services">
                    <main className="main">
                        <Banner title={servicesData?.title[lang]} img={servicesData?.images} />
                        <Transportservice data={transportData}/>
                        <ServiceLeave data={leaveData}/>
                        <ServiceSteps data={stepsData}/>
                        <ServiceInfo data={infoData}/>
                    </main>
                    <Footer />
                </div>
            </>
            :
            <Loader/>
    )
}

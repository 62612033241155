import React, {useEffect, useState} from 'react'
import {Banner} from '../../components/banner'
import {Freight} from '../../components/Freight'
import {Footer} from '../../components/footer'
import {Header} from "../../components/header";
import axios from "axios";
import {useParams} from "react-router";
import {Loader} from "../../components/loader";

export const ServiceDetalisPage = () => {
    const [singleServices, setSingleServices] = useState(null)
    const params = useParams()

    useEffect(() => {
        setSingleServices(null)
        axios.get(process.env.REACT_APP_NODE_API + '/singleService/getAllPage').then(
            r => {
                if (!r.data.error) {
                    setSingleServices(r.data.find(el => (el.url === params.serviceType)))
                    document.title = 'Vila Trans / ' + r.data.find(el => (el.url === params.serviceType)).title[params.lang]
                }
            }
        )
    }, [params.serviceType])

    return (
        singleServices ?
            <>
                <Header/>
                <div className="page-wrapper">
                    <main className="main">
                        <Banner title={singleServices.title[params.lang]} img={singleServices.banner}/>
                        <Freight content={singleServices.content[params.lang]}/>
                    </main>
                    <Footer/>
                </div>
            </>
            :
            <Loader/>
    )
}

import React, {useEffect, useState} from 'react';
import {Banner} from "../../components/banner";
import {About} from "./About";
import {Achievement} from "./Achievement";
import {Features} from "../../components/Features";
import {Quote} from "../../components/Quote";
import {Services} from "../../components/Services";
import {Footer} from "../../components/footer";
import {Header} from "../../components/header";
import axios from "axios";
import langsLib from "../../assets/libs/LangsLib.json"
import {useParams} from "react-router";
import {Loader} from "../../components/loader";

export const AboutPage = () => {
    const {lang} = useParams();
    const [bannerData, setBannerData] = useState(null);
    const [info1Data, setInfo1Data] = useState(null);
    const [info2Data, setInfo2Data] = useState(null);
    const [featuresData, setFeaturesData] = useState(null);
    const [achievementData, setAchievementData] = useState(null);

    useEffect(() => {
        requests()
    }, [])

    const requests = async () => {
        await axios.get(process.env.REACT_APP_NODE_API + '/about/getBanner').then(res => {
            setBannerData(res.data.content[0]);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/about/getInfoBlockOne').then(res => {
            setInfo1Data(res.data.content[0]);
        });

        await axios.get(process.env.REACT_APP_NODE_API + '/service/info').then(res => {
            setInfo2Data(res.data);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/home/get-features').then(res => {
            setFeaturesData(res.data);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/about/getInfoMap').then(res => {
            setAchievementData(res.data.content[0]);
        })
    }

    return (
        achievementData ?
            <>
                <Header/>
                <div className="page-wrapper">
                    <main className="main">
                        <Banner title={langsLib.aboutBanner[lang]} img={bannerData ? bannerData : null}/>
                        <About info1Data={info1Data} info2Data={info2Data}/>
                        <Achievement data={achievementData}/>
                        <Features featuresData={featuresData}/>
                        <Quote/>
                        <Services/>
                    </main>
                    <Footer/>
                </div>
            </>
            :
            <Loader/>
    );
}

export default AboutPage;
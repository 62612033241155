import CallIcon from '@mui/icons-material/Call';
import {Link} from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useLocation, useNavigate, useParams} from "react-router";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useEffect, useState} from "react";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {useMediaQuery} from "@mui/material";
import {IconChevronDown} from "@tabler/icons-react";
import langsLib from "../assets/libs/LangsLib.json"
import axios from "axios";
import {Loader} from "./loader";

const Language = () => {
    const [open, setOpen] = useState(false)
    const params = useParams()
    const {pathname} = useLocation()
    const media = useMediaQuery('(max-width: 1120px)')

    const languageArr = {
        en: 'ENG',
        ru: 'Рус',
        hy: 'Հայ',
    }

    const generateString = (lang) => {
        const string = pathname.split('/').slice(1, -1).toString().replace(/,/g, '/')
        if (string === ''){
            return lang
        }else{
            return string+'/'+lang
        }
    }

    const languageArrKeys = Object.keys(languageArr)

    return(
        <div className={'languageSelect'}>
            <div
                style={{color: media ? '#29a948' : '#fff'}}
                className="languageSelect__btn" onClick={() => setOpen(!open)}>
                {languageArr[params.lang]}
                <IconChevronDown/>
            </div>
            {
                open ?
                    <div className="languageSelect__values">
                        {
                            Object.values(languageArr).map((el,i) => (
                                el !== languageArr[params.lang] ?
                                    <Link to={'/'+generateString(languageArrKeys[i])} key={el+i} onClick={() => setOpen(false)}>{el}</Link>
                                    :
                                    null
                            ))
                        }
                    </div>
                    :
                    null
            }
        </div>
    )
}

export const Header = () => {
    const [servicesNext, setServiceNext] = useState(false)
    const [mobMenu, setMobMenu] = useState(false)
    const [dropdownMenu, setDropdownMenu] = useState(false)
    const media = useMediaQuery('(max-width: 1120px)')
    const navigate = useNavigate()
    let {pathname} = useLocation()
    pathname = pathname.split('/').slice(0, -1).toString().replace(/,/g, '/')
    const params = useParams();
    const {lang} = params;
    const [singleServices, setSingleServices] = useState([])
    const [headerObj, setHeaderObj] = useState({})


    useEffect(() => {
        axios.get(process.env.REACT_APP_NODE_API + '/header/getHeader').then(
            r => {
                if (!r.data.error) {
                    setHeaderObj(r.data)
                }
            }
        )

        axios.get(process.env.REACT_APP_NODE_API + '/singleService/getAllPage').then(
            r => {
                if (!r.data.error) {
                    setSingleServices(r.data)
                }
            }
        )
    }, [])

    return(
        headerObj.phone ?
        <div className={'header'}>
            <div className={'sub-header'}>
                <div className="container">
                    <div className="logo">
                        <img
                            src={headerObj.logo}
                            alt="logo"
                            onClick={() => navigate('/')}
                        />
                    </div>
                    <div className="header__right">
                        <div className="header__right__top">
                            <div className="header__right__top--call">
                                <CallIcon/>
                                <div>
                                    <a href={`tel:${headerObj.phone.replace(/ /g, '')}`}>{headerObj.phone}</a>
                                </div>
                            </div>
                            <div className="header__right__top--mail">
                                <MailOutlineIcon/>
                                <div>
                                    <a href={`mailto:${headerObj.email}`}>{headerObj.email}</a>
                                </div>
                            </div>
                            <Link to={'/calculator/'+params.lang} className="header__right__top--btn">{langsLib.header.contacts.btn[lang]}</Link>
                            <Language/>
                        </div>
                        <div className={'dec-line'}/>
                        <div className="header__right__bottom">
                            <Link to={'/'+params.lang} className={pathname === '' ? 'active' : ''}>{langsLib.header.navbar.home[lang]}</Link>
                            <Link to={'/about/'+params.lang} className={pathname === '/about' ? 'active' : ''}>{langsLib.header.navbar.about[lang]}</Link>
                            <div className={'dropdown'}
                                 onMouseEnter={() => setDropdownMenu(true)}
                                 onMouseLeave={() => setDropdownMenu(false)}
                            >
                                <Link to={'/service/'+params.lang} className={pathname === '/service' ? 'active' : ''}>{langsLib.header.navbar.services[lang]} <ArrowDropDownIcon/></Link>
                                {
                                    dropdownMenu ?
                                        <div className={'dropdown__menu'}>
                                            {
                                                singleServices?.map((el, i) => (
                                                    <Link key={el.url+i} to={`/service/${el.url}/`+params.lang} className={pathname === `/service/${el.url}`? 'active' : ''}>{el.title[lang]}</Link>
                                                ))
                                            }
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <Link to={'/calculator/'+params.lang} className={pathname === '/calculator' ? 'active' : ''}>{langsLib.header.navbar.calculator[lang]}</Link>
                            <Link to={'/contact/'+params.lang} className={pathname === '/contact' ? 'active' : ''}>{langsLib.header.navbar.contact[lang]}</Link>
                        </div>
                    </div>
                    {
                        media ?
                            <Language/>
                        :
                            null
                    }
                    <div style={{marginLeft: 5}} className={'mob-menu-btn'} onClick={() => setMobMenu(!mobMenu)}>
                        <MenuIcon sx={{width: 40, height: 40, color: "black"}}/>
                    </div>
                </div>
            </div>
            {
                media ?
                    <div className={`mob-menu ${mobMenu ? 'active' : ''}`}>
                        {
                            !servicesNext ?
                                <>
                                    <Link to={'/'+params.lang} className={pathname === '/' ? 'active' : ''}>{langsLib.header.navbar.home[lang]}</Link>
                                    <Link to={'/about/'+params.lang} className={pathname === '/about' ? 'active' : ''}>{langsLib.header.navbar.about[lang]}</Link>
                                    <Link to={'/service/'+params.lang} className={pathname === '/service' ? 'active' : ''} onClick={() => setServiceNext(true)}>{langsLib.header.navbar.services[lang]} <ArrowRightIcon/></Link>
                                    <Link to={'/calculator/'+params.lang} className={pathname === '/calculator' ? 'active' : ''}>{langsLib.header.navbar.calculator[lang]}</Link>
                                    <Link to={'/contact/'+params.lang} className={pathname === '/contact' ? 'active' : ''}>{langsLib.header.navbar.contact[lang]}</Link>
                                </>
                                :
                                <>
                                    <div className={'mob-menu--perv'} onClick={() => setServiceNext(false)}>
                                        <ArrowLeftIcon sx={{width: 40, height: 40, color: "white"}}/>
                                    </div>
                                    {
                                        singleServices?.map((el, i) => (
                                            <Link key={el.url+i} to={`/service/${el.url}/`+params.lang} className={pathname === `/service/${el.url}`? 'active' : ''}>{el.title[lang]}</Link>
                                        ))
                                    }
                                </>
                        }
                    </div>
                    :
                    null
            }
        </div>
            :
            <Loader/>
    )
}
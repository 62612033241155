import React  from "react";
import roadImg from "../assets/images/services/road_freight.jpg";
import trainImg from "../assets/images/services/train.jpg";
import planeImg from "../assets/images/services/plane.jpg";
import shipImg from "../assets/images/services/ship.jpg";
import { useParams } from "react-router";
import langsLib from "../assets/libs/LangsLib.json"

export const ServiceLeave = ({data}) => {
    const params = useParams()
    const { lang } = params

    return (
        <>
            <section className="section bg--lgray pb-0 services-inner pt-5">
                <div className="container bottom-70">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading heading--center">
                                <span className="heading__pre-title">{langsLib.services.title[lang]}</span>
                                <h3 className="heading__title">
                                    {langsLib.services.leaveTitle[lang]}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container--wide">
                    <div className="row offset-50">
                        <div className="col-md-6 col-xl-3">
                            <div className="img-box">
                                <div className="img-box__overlay"></div>
                                <div className="img-box__text-layout">{langsLib.services.leavePreTitle[lang]}</div>
                                <img className="img--bg" src={roadImg} alt="road freight" />
                                <div className="img-box__details">
                                    <h5 className="img-box__title color--white fw-7 ">
                                       {langsLib.services.truck.title[lang]}
                                    </h5>
                                    <div className="color--white ">01</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="img-box">
                                <div className="img-box__overlay"></div>
                                <div className="img-box__text-layout">{langsLib.services.leavePreTitle[lang]}</div>
                                <img className="img--bg" src={trainImg} alt="train freight" />
                                <div className="img-box__details">
                                    <h5 className="img-box__title color--white fw-7">
                                       { langsLib.services.train.title[lang]}
                                    </h5>
                                    <div className="color--white ">02</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="img-box">
                                <div className="img-box__overlay"></div>
                                <div className="img-box__text-layout">{langsLib.services.leavePreTitle[lang]}</div>
                                <img className="img--bg" src={planeImg} alt="plane freight" />
                                <div className="img-box__details">
                                    <h5 className="img-box__title color--white fw-7">
                                        {langsLib.services.plane.title[lang]}
                                    </h5>
                                    <div className="color--white ">03</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="img-box">
                                <div className="img-box__overlay"></div>
                                <div className="img-box__text-layout">{langsLib.services.leavePreTitle[lang]}</div>
                                <img className="img--bg" src={shipImg} alt="ship freight" />
                                <div className="img-box__details">
                                    <h5 className="img-box__title  color--white fw-7">
                                       {langsLib.services.ship.title[lang]}
                                    </h5>
                                    <div className="color--white ">04</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

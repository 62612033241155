import {useEffect, useState} from 'react';
import draftToHtml from 'draftjs-to-html';

export const ConvertToHtml = (rawObj) => {
    const [html, setHtml] = useState(null);

    useEffect(() => {
        setHtml(draftToHtml({...rawObj, entityMap: {}}));
    }, [rawObj])

    return html
}
import React, {useEffect, useState} from 'react';
import {Promo} from "./Promo";
import {TransportServices} from "./TransportServices";
import {Features} from "../../components/Features";
import {Services} from "../../components/Services";
import {Quote} from "../../components/Quote";
import {Footer} from "../../components/footer"
import {HomeAchievement} from "./HomeAchievement";
import {QuoteTrack} from "./QuoteTrack";
import {Header} from "../../components/header";
import {Loader} from "../../components/loader";
import axios from "axios";

export const HomePage = () => {
    const [promoData, setPromoData] = useState(null);
    const [serviceData, setServiceData] = useState(null);
    const [featuresData, setFeaturesData] = useState(null);
    const [selectData, setSelectData] = useState({
        delivery: {
            en: [],
            ru: [],
            hy: [],
        },
        origin: {
            en: [],
            ru: [],
            hy: [],
        },
        service: {
            en: [],
            ru: [],
            hy: [],
        }
    });
    const [achievementData, setAchievementData] = useState(null);

    useEffect(() => {
        requests()
    }, [])

    const requests = async () => {
        await axios.get(process.env.REACT_APP_NODE_API + '/home/slide-data').then(res => {
            setPromoData(res.data)
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/home/get-services').then(res => {
            setServiceData(res.data);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/home/get-features').then(res => {
            setFeaturesData(res.data);
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/pages/calculator/selection').then(res => {
            const {delivery, origin, customs} = res.data.data[0].select;
            setSelectData({
                delivery: {
                    en: delivery.map(elem => elem.value.en),
                    ru: delivery.map(elem => elem.value.ru),
                    hy: delivery.map(elem => elem.value.hy),
                },
                origin: {
                    en: origin.map(elem => elem.value.en),
                    ru: origin.map(elem => elem.value.ru),
                    hy: origin.map(elem => elem.value.hy),
                },
                customs: {
                    en: customs.map(elem => elem.value.en),
                    ru: customs.map(elem => elem.value.ru),
                    hy: customs.map(elem => elem.value.hy),
                }
                
            });
        })

        await axios.get(process.env.REACT_APP_NODE_API + '/about/getInfoMap').then(res => {
            setAchievementData(res.data.content[0]);
        })
    }
    return (
        achievementData ?
            <>
                <Header/>
                <main className="main">
                    <Promo promoData={promoData}/>
                    <TransportServices serviceData={serviceData}/>
                    <Features featuresData={featuresData}/>
                    <Services/>
                    <Quote/>
                    <HomeAchievement data={achievementData}/>
                    <QuoteTrack selectData={selectData}/>
                    <Footer/>
                </main>
            </>
            :
            <Loader/>
    );
}